<template>
  <!-- 视频推荐位列表页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <!-- 筛选和表格 -->
        <div class="right-content">
          <!-- 顶部筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 视频推荐位置 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="视频推荐位置">
                    <a-input v-model="queryParam.position" allow-clear placeholder="请输入视频推荐位置" />
                  </a-form-item>
                </a-col>

                <a-col :md="8" :sm="24">
                  <a-button type="primary" icon="search" @click="getList">搜索</a-button>
                  <a-button icon="reload" style="margin: 0 8px" @click="reset">重置</a-button>
                  <a-button icon="plus-circle" style="background-color: #52c41a; color: #fff" @click="showDrawer = true"
                    >添加</a-button
                  >
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <!-- 操作插槽 -->
            <div slot="action" slot-scope="text, record">
              <a-button @click="$refs.detailForm.getDetail(record)">详情</a-button>

              <a-button type="primary" style="margin: 0 10px" @click="edit(record)"> 编辑 </a-button>
              <a-button type="danger" @click="remove(record)"> 删除 </a-button>
            </div>
          </a-table>

          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            >
            </a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>

    <detail-form ref="detailForm"></detail-form>
    <!--   添加运费模板 -->
    <custom-drawer ref="customDrawer" v-bind:visible.sync="showDrawer" />
  </div>
</template>

<script>
import detailForm from './detailForm.vue'
import CustomDrawer from './custom-drawer'
import { recommendPositionPage, removeRecommendPosition } from '@/api/modular/mallLiving/videoClass'
const columns = [
  {
    title: '序号',
    align: 'center',
    width: '100px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '视频推荐位置',
    align: 'center',
    dataIndex: 'position',
    key: 'position',
  },
  // {
  //   title: '开始时间',
  //   align: 'center',
  //   dataIndex: 'beginTime',
  //   key: 'beginTime',
  // },
  // {
  //   title: '结束时间',
  //   align: 'center',
  //   dataIndex: 'endTime',
  //   key: 'endTime',
  // },

  {
    title: '操作',
    align: 'center',
    width: 'auto',
    dataIndex: 'action',
    fixed: 'right',
    key: 'action',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      showDrawer: false,
      queryParam: {
        position: '',
      },
    }
  },
  components: {
    CustomDrawer,
    detailForm,
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    reset() {
      this.queryParam = { position: '' }
      this.getList()
    },

    edit(record) {
      this.showDrawer = true
      this.$refs.customDrawer.getDetail(record.id)
    },
    remove(record) {
      this.$confirm({
        title: '提示',
        content: '确定删除当前推荐位？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          console.log('OK')
          //delFlag: "删除标志 1正常 2删除 3 禁用"
          removeRecommendPosition({ id: record.id, delFlag: 2 })
            .then((res) => {
              if (res.success) {
                this.$message.success('删除当前推荐位成功')
                this.getList()
              }
            })
            .catch((error) => {
              this.$message.error(error.message || '删除当前推荐位失败')
            })
        },
      })
    },
    getList() {
      this.loading = true
      var params = {
        page: this.page,
        queryParam: this.queryParam,
      }
      recommendPositionPage(params)
        .then((res) => {
          if (res.success) {
            let data = res.data.rows
            if (data && Array.isArray(data)) {
              this.data = data.map((item) => ({ ...item, isDefault: +item.isDefault === 2 }))
            }
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 2000)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.right-content-top {
  box-sizing: border-box;
  padding-bottom: 20px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

// 顶部信息栏样式
.header-info {
  .bg-info {
    background: rgba(255, 255, 255, 100);
    padding: 5px;

    img {
      width: 95px;
      height: 107px;
    }

    .icon-wrapper {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;

      h1 {
        font-size: 23px;
        line-height: 25px;
      }
    }
  }
}
</style>
